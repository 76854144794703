import i18n from '@/plugins/i18n'
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js'
import helpers from '@/helpers'

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export default {
  required: (value) => !!value || i18n.t('error_required'),
  legalAccepted: (value) => !!value || i18n.t('error_consent_missing'),
  // password policy: > 8 chars, +1 lowercase, +1 uppercase
  pwd: (value) => {
    if (!value) {
      return true
    }
    if (value.length < 8) {
      return i18n.t('pwd_rule_length')
    }
    const pwdPattern = /(?=.*[a-z])(?=.*[A-Z])/
    return pwdPattern.test(value) || i18n.t('pwd_rule_format')
  },
  email: (value) => {
    return EMAIL_REGEX.test(value) || i18n.t('error_invalid_email')
  },
  phone: (value) => {
    if (!isValidNumber(value)) {
      return i18n.t('signup_phone_error')
    }

    try {
      const phoneNumber = parsePhoneNumber(value)
      const country = phoneNumber.country.toLowerCase()

      return (
        helpers.isCountrySupported(country) || i18n.t('g_unsupported_country')
      )
    } catch (error) {
      return i18n.t('signup_phone_error')
    }
  },
  identifier: (value) => {
    // an identifier should be a valid email or phone number
    const condition = EMAIL_REGEX.test(value) || isValidNumber(value)
    return condition || i18n.t('invalid_identifier')
  }
}
